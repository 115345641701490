<template>
  <div class="doctorMainDiv">
    <NavBar style="max-height:50px;" class="topNavBarClass" :username="currentUserName" :pageName="pageName" solo>
    </NavBar>

    <v-app style="width: 100vw;">
      <div>
        <v-row cols="12">
          <v-col cols="2" class="sideBarClass">
            <SideBar style="height: 100%;" :pageName="pageName"></SideBar>
          </v-col>
          <v-col cols="10" class="col10Class" style="overflow:scroll; height:95vh">
            <div style="margin: 20px 50px" class="allappointmentDivClass">
              <div style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                  ">
                <div align="left" class="upcommingTitleClass">
                  <span style="
                        font-weight: 800;
                        padding-left: 5px;
                        margin-top: 10px;
                        font-size: 12pt;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                      " solo>Upcoming Appointment</span>
                </div>
                <div style="display: flex" class="serchBoxClass">
                  <div style="display: flex; flex-direction: row" v-if="search_type == 'Date'">
                    <div>
                      <p style="margin-top: 18px; font-weight: bold">From:</p>
                    </div>
                    <div>
                      <input type="date" v-model="start_date" @change="(phone_number = ''), (nrc_number = '')" style="
                            margin-top: 7%;
                            background-color: white;
                            width: 90%;
                            padding: 3%;
                            box-shadow: 0px 8px 30px 0px #0547891a;
                            color: #1467bf;
                            border-radius: 5px;
                          " />
                    </div>
                  </div>
                  <div style="display: flex; flex-direction: row" v-if="search_type == 'Date'">
                    <div>
                      <p style="margin-top: 18px; font-weight: bold">To:</p>
                    </div>
                    <div>
                      <input type="date" v-model="end_date" style="
                            margin-top: 7%;
                            background-color: white;
                            width: 90%;
                            padding: 3%;
                            box-shadow: 0px 8px 30px 0px #0547891a;
                            color: #1467bf;
                            border-radius: 5px;
                          " @change="(phone_number = ''), (nrc_number = '')" />
                    </div>
                    <div v-if="start_date && end_date">
                      <a>
                        <img @click="filterWithDateRange" style="margin-top: 14px; height: 2em"
                          src="https://s3iconimages.mymedicine.com.mm/search.svg" /></a>
                    </div>
                  </div>

                  <div style="
                        display: flex;
                        justify-content: flex-end;
                        margin: 10px 5px;
                        margin-top: 3 %;
                      ">
                    <v-select v-model="search_type" :items="items" placeholder="Search With" solo dense @change="
                      (startDate = ''), (nrc_number = ''), (phone_number = '')
                      " style="width: 30%; margin-right: 5px"></v-select>

                    <v-text-field v-if="search_type == 'NRC'" v-model="nrc_number" label="Enter NRC number" dense solo
                      style="width: 50%">
                      <template v-slot:append>
                        <v-btn @click="filterAppointment" icon>
                          <img style="width: 25px; height: 25px"
                            src="https://s3iconimages.mymedicine.com.mm/search.svg" />
                        </v-btn>
                      </template>
                    </v-text-field>

                    <v-text-field v-else-if="search_type == 'Phone Number'" v-model="phone_number"
                      placeholder="Type here" label="Enter Phone number" :rules="mobileNumberRules" dense solo
                      style="width: 50%"><template v-slot:append>
                        <v-btn @click="filterAppointment" icon>
                          <img style="width: 25px; height: 25px"
                            src="https://s3iconimages.mymedicine.com.mm/search.svg" />
                        </v-btn>
                      </template>
                    </v-text-field>

                    <v-text-field v-else-if="search_type != ''" v-show="false"></v-text-field>
                  </div>
                </div>
              </div>
              <div class="responsiveSearch">
                <!-- <v-text-field
                    flat
                    solo
                    placeholder="Search with"
                    :type="searchType"
                    class="pa-0 ma-0 responsiveTextField"
                    @focusout="checkingInput"
                  >
                  <template v-slot:append class="d-flex ">
                    <v-divider vertical dark class="responsiveDivider"></v-divider>
                    <v-select
                    v-model="search_type"
                    :items = "items"
                    class="responsiveSerachSelect ma-0 pa-0"
                    flat
                    solo
                    placeholder="Select Search"
                    @change="search_type === 'Date' ? searchType = 'Date' : searchType = 'text' "
                    >
                    </v-select>
                  </template>
                  </v-text-field>
                  <div style="margin: 5px">
                    <v-btn class="primary" style="width: 90%">Search</v-btn>
                  </div> -->
                <div class="responsiveSearchType">
                  <div style="width: 50%">
                    <p style="margin-top: 10px; font-size: 16px; font-weight: bold; text-align: left;"> Search With</p>
                  </div>
                  <div>
                    <v-select v-model="search_type" :items="items" style="display:flex; justify-content: flex-end;" flat
                      solo placeholder="Select Search"
                      @change="(phone_number = ''), (start_date = ''), (end_date = '')">
                    </v-select>
                  </div>
                </div>
                <div v-if="search_type === 'Date'" class="d-flex responsiveDate">
                  <div v-for="date in listOfDate" :key="date">
                    <v-chip-group v-model="start_date" @change="filterWithDateRange(), (end_date = '')">
                      <v-chip :value="date" class="responsiveChip">{{ date }}</v-chip>
                    </v-chip-group>
                  </div>
                </div>
                <div v-if="search_type === 'Phone Number'">
                  <v-text-field v-model="phone_number" type="number" placeholder="Type Number" flat solo>
                    <template v-slot:append>
                      <img width="40" @click="filterAppointment"
                        src="https://s3iconimages.mymedicine.com.mm/search.svg">
                    </template>
                  </v-text-field>
                </div>
              </div>
              <div>
                <div style="display: flex;flex-flow: row wrap;" v-if="!isLoading" class="allappointmentClass">
                  <div v-for="appointment in allAppointments" :key="appointment._id"
                    style="padding: 20px; border-radius: 8px; width: 33%" class="appintmentCardClass">
                    <v-card elevation="3" outlined style="
                          border-radius: 7px;
                          box-shadow: 0px 8px 20px rgba(5, 71, 137, 0.1);
                        ">
                      <div style="
                            display: flex;
                            justify-content: space-between;
                            padding: 20px 0px;
                          ">
                        <div style="display: row; padding-left: 15px">
                          <div style="
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;
                              ">
                            <img src="https://img.icons8.com/color/50/000000/user.png" />
                            <div style=" font-size: 0.9rem;">
                              {{ appointment.customer_gender }}
                            </div>
                          </div>
                        </div>
                        <div style="
                              margin-top: auto;
                              margin-bottom: auto;
                              padding-right: 20px;
                            ">
                          <v-btn depressed class="primary" style="color: white; font-weight: 400; width: 100%; background-color: #1467BF;"
                            @click="redirectToVideoCall(appointment._id)" :loading="joinLoading"
                            v-if="appointment.isLinkActive">
                            <v-icon left> mdi-video </v-icon>
                            Join
                          </v-btn>
                          <!-- <v-btn
                              depressed
                              class="primary"
                              style="color: white; font-weight: 400; width: 100%"
                              @click="redirectToViewAppointment(appointment._id)"
                              v-else
                            >
                              View
                            </v-btn> -->
                        </div>
                      </div>
                      <div style="
                            display: flex;
                            justify-content: space-between;
                            padding: 10px 0px;
                          ">
                        <div style="display: flex; padding-left: 15px" class="doctorHomeDateClass">
                          <img src="https://s3iconimages.mymedicine.com.mm/calendar.svg"
                            style="height: 24px; width: 25px" />
                          <DateRendered :dateVal="appointment.date_of_appointment" style="margin-top: 1%"
                            class="dateClass">
                          </DateRendered>
                        </div>
                        <div style="display: flex; padding-right: 15px">
                          <div>
                            <img src="https://s3iconimages.mymedicine.com.mm/clock.svg" />
                          </div>
                          <div>
                            <TimeRender :timeSlot="appointment.time_slot" class="timeClass"></TimeRender>
                          </div>
                        </div>
                      </div>
                      <v-dialog v-model="dialog" persistent max-width="400">
                        <template v-slot:activator="{ on, attrs }">
                          <div style="margin: 10px;">
                            <v-btn depressed class="error" style="color: white; font-weight: 400; width: 100%; background-color: #EB5757;"
                              @click="confirmClosePopup(appointment._id)" :loading="closeAppointmentBTNLoading"
                              v-if="appointment.isCloseBTNActive">
                              <v-icon left> mdi-video </v-icon>
                              Close Appointment
                            </v-btn>
                          </div>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5">
                            Close this Appointment
                          </v-card-title>
                          <v-card-text style="text-align: left;">Are you sure you want to close this appointment? Once
                            the appointment
                            is closed you won't be able to join the call again.</v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn outlined color="error" @click="dialog = false" style="background-color: #EB5757;">
                              Cancel
                            </v-btn>
                            <v-btn depressed color="primary" @click="closeAppointment()" style="background-color: #1467BF;">
                              Confirm
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <div v-if="!appointment.isCloseBTNActive" class="notificationClass" style="
                          font-size: 10pt;
                          background: #ffe3da;
                          border-radius: 0px 0px 7px 7px;
                          bottom: 0%;
                        ">
                        <p class="notificationTextClass">
                          You can join the call before 10 minutes
                        </p>
                      </div>
                    </v-card>
                  </div>
                  <div v-if="allAppointments.length == 0">
                    No Appointments yet.
                  </div>
                </div>
                <v-progress-circular indeterminate color="primary" v-else />
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-app>
    <bottomNavBar :pageName='value' class="bottomNavBar"></bottomNavBar>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import moment from "moment";
import DateRendered from "../Customer/dateRender.vue";
import TimeRender from '../Customer/TimeRender.vue';
import SideBar from "../../../views/sideBar.vue";
import { handleError } from '../../../utils/utils';
import { axios_auth_instance_doctor } from '../../../utils/axios_utils'

export default {
  name: "DoctorCPIHome",
  data() {
    return {
      joinLoading: false,
      dialog: false,
      closeAppointmentBTNLoading: false,
      start_date: "",
      end_date: "",
      startDate: "",
      displayDate: "",
      end_date: "",
      allAppointments: "",
      currentUser: "",
      currentUserName: "",
      pageName: "DoctorCPIHome",
      items: ["Date", "Phone Number"],
      nrc_number: "",
      phone_number: "",
      search_type: "",
      mobileNumberRules: [(v) => v > 0 || "Mobile number cannot be negative"],
      isLoading: false,
      value: "HomePage",
      listOfDate: [],
      searchType: '',
      appointmentID: null
    };
  },
  mounted() {
    document.title = "Home"
    var date_format = new Date();
    this.isLoading = true;
    this.startDate = date_format.toISOString().split("T")[0];

    this.currentUser = this.$cookies.get("doctorToken");
    if (!this.currentUser) {
      this.$router.push({
        name: "DoctorLogin",
      });
    } else {
      var data = {
        token: this.currentUser,
        typeOfUser: "DOCTOR"
      };
      axios_auth_instance_doctor.post("/doctorCPIHomePage", data)
        .then((successResponse) => {
          this.isLoading = false;
          this.currentUserName = successResponse.data.doctor_data.doctor_name;
          this.doctor_id = successResponse.data.doctor_data._id;
          this.displayDate = moment(new Date()).format("ll");
          this.allAppointments = successResponse.data.appointment_data;
          this.allAppointments.forEach((x) => {
            var dateNow = new Date();
            var get_diff = new Date(x.booking_epoch_time).getTime() - dateNow.getTime()
            var end_diff = new Date((x.booking_closing_epoch_time)) - dateNow.getTime()
            x.isLinkActive = (get_diff < 600000) ? true : false;
            x.isCloseBTNActive = end_diff <= 0;
          });
          let startDate = new Date().toISOString().substring(0, 10)
          let endDate = new Date();
          endDate.setDate(endDate.getDate() + 5);
          endDate = endDate.toISOString().substring(0, 10)
          this.listOfDate = this.getDaysArray(startDate, endDate)
        })
        .catch((errorResponse) => {
          this.isLoading = false;
          handleError(errorResponse, this.$router, 'doctorHomePage', 'DoctorLogin', 'doctorToken');
        });
    }
  },
  components: {
    NavBar,
    DateRendered,
    TimeRender,
    SideBar,
    bottomNavBar: () => import('../../../components/docBottomNavBar.vue')
  },
  methods: {
    confirmClosePopup(appointmentID) {
      this.appointmentID = appointmentID;
      this.dialog = true;
    },
    async closeAppointment() {
      try {
        if (this.appointmentID) {
          this.closeAppointmentBTNLoading = true;
          await axios_auth_instance_doctor.put(`/doctorCloseAppointment/${this.appointmentID}`, {
            token: this.currentUser,
            typeOfUser: "DOCTOR"
          });
          this.$router.go();
        }
      } catch (error) {
        alert('Something went wrong');
        console.log(error);
      } finally {
        this.closeAppointmentBTNLoading = false;
        this.dialog = false;
      }
    },
    getDaysArray(start, end) {
      for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
        let thisDate = new Date(dt)
        thisDate = thisDate.toISOString().substring(0, 10)
        arr.push(thisDate);
      }
      return arr;
    },
    filterWithDateRange() {
      if (this.end_date == '') {
        this.end_date = this.start_date
      }
      if (
        moment(this.start_date).format("YYYY-MM-DD") <
        moment(new Date()).format("YYYY-MM-DD") ||
        moment(this.end_date).format("YYYY-MM-DD") <
        moment(new Date()).format("YYYY-MM-DD")
      ) {
        alert("Please enter future dates only");
      } else {
        if (
          moment(this.start_date).format("YYYY-MM-DD") >
          moment(this.end_date).format("YYYY-MM-DD")
        ) {
          alert("Please enter valid date range");
        } else {
          var appointment_body = {
            fromDate: this.start_date,
            toDate: this.end_date,
            doctorId: this.doctor_id,
            token: this.currentUser,
            typeOfUser: "DOCTOR",
          };

          axios
            .post(
              process.env.VUE_APP_BACKEND_URL +
              "/viewAppointmentsByDoctorIdForParticularDate",
              appointment_body
            )
            .then((viewCurrentDateResponse) => {
              this.isLoading = false;
              this.allAppointments = viewCurrentDateResponse.data.data;
              this.allAppointments.forEach((x) => {
                var ending_time = new Date(
                  x.date_of_appointment +
                  " " +
                  x.time_slot.split("-")[1] +
                  ":00 GMT+0630"
                ).getTime();
                x.isLinkActive =
                  new Date(x.booking_epoch_time).getTime() -
                    new Date().getTime() <
                    600000 &&
                    new Date(ending_time).getTime() - new Date().getTime() > 0
                    ? true
                    : false;
              });
            })
            .catch((viewCurrentDateError) => {
              this.isLoading = false;
              if (viewCurrentDateError.response.status == 401) {
                window.alert("Sorry !! You are UNAUTHORIZED");
                this.$router.push({
                  name: "DoctorLogin",
                });
                this.$cookies.remove("doctorToken");
              }
            });
        }
      }
    },
    hisClick() {
      this.$router.push({
        name: "AppointmentHistory",
      });
    },
    filterAppointment() {
      this.isLoading = true;
      this.startDate = "";
      if (this.nrc_number != "" || this.phoneNumber != "") {
        var appointment_body = {
          NRC: this.nrc_number,
          phoneNumber: this.phone_number,
          doctorId: this.doctor_id,
          token: this.currentUser,
          typeOfUser: "DOCTOR",
        };
        this.displayDate = moment(new Date()).format("ll");
        axios.post(process.env.VUE_APP_BACKEND_URL +"/viewAppointmentsByDoctorIdForParticularDate",appointment_body)
          .then((viewCurrentDateResponse) => {
            this.isLoading = false;
            this.allAppointments = viewCurrentDateResponse.data.data;
            this.allAppointments.forEach((x) => {
              var ending_time = new Date(x.date_of_appointment +" " +x.time_slot.split("-")[1] +":00 GMT+0630").getTime();
              x.isLinkActive =new Date(x.booking_epoch_time).getTime() -new Date().getTime() < 600000 &&new Date(ending_time).getTime() - new Date().getTime() > 0? true: false;
            });
          })
          .catch((viewCurrentDateError) => {
            this.isLoading = false;
            if (viewCurrentDateError.response.status == 401) {
              window.alert("Sorry !! You are UNAUTHORIZED");
              this.$router.push({
                name: "DoctorLogin",
              });
              this.$cookies.remove("doctorToken");
            }
          });
      } else {
        window.alert("Search filed can't be empty");
      }
    },
    gotoLoginPage() {
      var deleteData = {
        idToken: this.currentUser,
      };
      axios
        .post(process.env.VUE_APP_BACKEND_URL + "/deleteAdminToken", deleteData)
        .then((deleteResponse) => {
          this.$cookies.remove("doctorToken");
        })
        .catch((error) => {
        });
      this.$router.push({
        name: "DoctorLogin",
      });
    },
    onChangeDate() {
      //this.doctor_id = this.doctor_id;
      this.start_date = this.startDate;
      var appointment_body = {
        date: this.start_date,
        doctorId: this.doctor_id,
        token: this.currentUser,
        typeOfUser: "DOCTOR",
      };
      this.displayDate = moment(this.startDate).format("ll");
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL +"/viewAppointmentsByDoctorIdForParticularDate",appointment_body)
        .then((viewCurrentDateResponse) => {
          this.allAppointments = viewCurrentDateResponse.data.data;
          this.allAppointments.forEach((x) => {
            var ending_time = new Date(
              x.date_of_appointment +
              " " +
              x.time_slot.split("-")[1] +
              ":00 GMT+0630"
            ).getTime();
            x.isLinkActive =
              new Date(x.booking_epoch_time).getTime() - new Date().getTime() <600000 &&new Date(ending_time).getTime() - new Date().getTime() > 0? true: false;
          });
        })
        .catch((viewCurrentDateError) => {
          alert(viewCurrentDateError);
          if (viewCurrentDateError.response.status == 401) {
            window.alert("Sorry !! You are UNAUTHORIZED");
            this.$router.push({
              name: "DoctorLogin",
            });
            this.$cookies.remove("doctorToken");
          }
        });
    },
    async redirectToVideoCall(appointmentId) {
      this.joinLoading = true;
      try {
        let data = {
          token: this.currentUser,
          typeOfUser: "DOCTOR",
          bookingId: appointmentId,
          page: 1,
          limit: 8
        };
        let apiResponse = await axios_auth_instance_doctor.post("/doctorVideoCallPageTwilioCPI", data)
        let meetingLink = apiResponse.data.twilio_token;
        this.$router.push({
          name: 'CPIVideoCallPage',
          params: {
            token: meetingLink,
            appointmentID: appointmentId
          }
        })
      } catch (error) {
        console.log(error);
      } finally {
        this.joinLoading = false;
      }
    },
    redirectToViewAppointment(appointmentId) {
      this.$router.push({
        path: "/doctor/viewappointment/" + appointmentId,
      });
    },
    viewAppointments() {
      let start_date = this.start_date;
      if (start_date != "") {
        var appointment_body = {
          date: start_date,
          doctorId: this.doctor_id,
          token: this.currentUser,
          typeOfUser: "DOCTOR",
        };
        axios
          .post(
            process.env.VUE_APP_BACKEND_URL +
            "/viewAppointmentsByDoctorIdForParticularDate",
            appointment_body
          )
          .then((viewAppointmentsResponse) => {
            this.allAppointments = viewAppointmentsResponse.data.data;
            this.allAppointments.forEach((x) => {
              var ending_time = new Date(
                x.date_of_appointment +
                " " +
                x.time_slot.split("-")[1] +
                ":00 GMT+0630"
              ).getTime();
              x.isLinkActive =
                new Date(x.booking_epoch_time).getTime() -
                  new Date().getTime() <
                  600000 &&
                  new Date(ending_time).getTime() - new Date().getTime() > 0
                  ? true
                  : false;
            });
          })
          .catch((viewAppointmentsError) => {
            if (viewAppointmentsError.response.status == 401) {
              window.alert("Sorry !! You are UNAUTHORIZED");
              this.$router.push({
                name: "DoctorLogin",
              });
              this.$cookies.remove("doctorToken");
            }
          });
      } else {
        alert("Please select starting date");
      }
    },
  },
};
</script>

<style scoped>
.cardClass {
  margin-left: 20px;
  width: 70%;
}

.upcommingTitleClass {
  width: 30%;
}

.bottomNavBar {
  display: none;
}

.responsiveSearch {
  display: none;
}

.notificationTextClass {
  font-size: 1.1em;
  width: 100%;
  height: 25px;
  padding: 2px;
}

@media (min-width: 320px) and (max-width: 1024px) {
  .topNavBarClass {
    display: none;
  }

  .responsiveSearch {
    display: block;
  }

  .serachCard {
    display: flex;
    flex-direction: row;
    height: 45px;
    border-radius: 10px;
  }

  .responsiveDate {
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .responsiveChip {
    margin-bottom: 10px;
  }

  .notificationTextClass {
    font-size: 1em;
    width: 100%;
    height: unset;
    padding: 2px;
  }

  /*.responsiveDivider{
    border-width: 1px !important;
    border-color: #E0E0E0 !important;
    border-radius: 10px !important;
    height: 10px !important;
    margin-top: 8px !important;
    min-height: 28px !important;
    margin-left: 2px !important;
    margin-right: 2px !important
  }
  .responsivesearchButton{
    height: 75%;
    margin: 5px 5px;
  }
  .responsiveTextField{
    border-radius: 12px;
  } */
  .responsiveSearchType {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 22px;
  }

  .responsiveSearch {
    display: flex;
    flex-direction: column;
  }

  .bottomNavBar {
    display: block;
    position: fixed;
    width: 100%;
    bottom: 0px;
  }

  .serchBoxClass {
    display: none !important;
  }

  .upcommingTitleClass {
    width: 100%;
  }

  /* .doctorMainDiv{
    max-width: 425px;
    margin-right: auto;
    margin-left: auto;
  } */
  .sideBarClass {
    display: none;
  }

  .allappointmentClass {
    /* display: flex;
    flex-direction: column;
    flex-wrap: wrap; */
    width: 85vw;
    padding-bottom: 80px;
  }

  .appintmentCardClass {
    width: 20rem !important;
    padding: 10px !important;
  }

  .col10Class {
    max-width: none;
  }

  .doctorHomeDateClass {
    padding-left: 2px !important;
  }

  .dateClass {
    font-size: 12px;
    margin-top: 5%;
  }

  .timeClass {
    font-size: 12px;
    margin-top: 5%;
  }

  .notificationClass {
    display: block;
  }

  .allappointmentDivClass {
    margin: 20px 18px !important;
  }

  .col-10 {
    flex: unset;
  }
}

@media (max-width: 480px) {

  .AppHis {
    display: none;
  }

  .bottomNavBar {
    display: block;
  }

}
</style>
<style>
.responsiveTextField .v-input__slot {
  padding: unset !important;
  margin: unset !important;
}

.responsiveTextField .v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}

.responsiveTextField .v-text-field__details {
  display: none !important;
}

.responsiveTextField .v-text-field__slot {
  margin-left: 10px
}
</style>